import React from 'react'
import "../style/OurTeam.css";
import { Link } from "react-router-dom";

function OurTeam() {
  return (
    <div id='OurTeam'>
      <h1>Our Team</h1>
      <h4>AkuCuciin</h4>
      <div className='div-ourTeam'>
        <a href=""><img src="Images/Sahrul.png" alt="" /></a>
        <a href=""><img src="Images/Argy.png" alt="" /></a>
        <a href=""><img src="Images/Iqbal.png" alt="" /></a>
        <a href=""><img src="Images/Walid.png" alt="" /></a>
        <a href=""><img src="Images/Dhemia.png" alt="" /></a>
        <a href=""><img src="Images/Calysta.png" alt="" /></a>
        <a href=""><img src="Images/Hanif.png" alt="" /></a>
        <a href=""><img src="Images/Dzikra.png" alt="" /></a>
        <a href=""><img src="Images/Mutia.png" alt="" /></a>
        <a href=""><img src="Images/Ifzal.png" alt="" /></a>
        <a href=""><img src="Images/Faris.png" alt="" /></a>
        <a href=""><img src="Images/Gading.png" alt="" /></a>
      </div>
    </div>
  )
}

export default OurTeam
